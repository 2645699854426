<template>
    <div class="legal text-left">
        <b-container>
            <div class="text-center mb-5">
                <img src="../../assets/svg/buenpuerto-logo.svg" width="160">
            </div>
            <div class="text-right text-sm mb-5">Actualizado a 26 de septiembre de 2022</div>
            <h5 class="bold mb-5">POLÍTICA DE COOKIES</h5>
            <div class="legal-block">
                <p>
                    Según lo establecido por el artículo 22.2. de la de Servicios para la Sociedad de la Información (LSSI), los prestadores de servicios podrán utilizar cookies, siempre y cuando el usuario así lo haya consentido, tras haber recibido información de manera clara y completa sobre los fines de las mismas.
                </p>
                <p>
                    La Web <a href="https://somosbuenpuerto.com">https://somosbuenpuerto.com</a> usa cookies.
                </p>
                <p>
                    A continuación, encontrará información sobre qué son las cookies, qué tipo de cookies utiliza esta Web, cómo puede desactivar las cookies en su navegador o cómo seleccionar específicamente las cookies que permite que se instalen con su visita.
                </p>
            </div>
            <div class="legal-block">
                <div class="title">
                    ¿Qué son las cookies?
                </div>
                <p>
                    Las cookies son pequeños archivos que algunas plataformas, como las páginas web, pueden instalar en su ordenador, smartphone, tableta o televisión conectada, al acceder a las mismas. Sus funciones pueden ser muy variadas: almacenar sus preferencias de navegación, recopilar información estadística, permitir ciertas funcionalidades técnicas, almacenar y recuperar información sobre los hábitos de navegación o preferencias de un usuario o de su equipo hasta el punto, en ocasiones, dependiendo de la información que contengan y de la forma en que utilice su equipo, de poder reconocerlo. Una cookie se almacena en un ordenador para personalizar y facilitar al máximo la navegación del usuario. Las cookies se asocian únicamente a un usuario y su ordenador y no proporcionan referencias que permitan deducir datos personales del usuario. El usuario podrá configurar su navegador para que notifique o rechace la instalación de las cookies enviadas por el sitio web.
                </p>
            </div>
            <div class="legal-block">
                <div class="title">
                    ¿Por qué son importantes?
                </div>
                <p>
                    Las cookies son útiles por varios motivos.  Desde un punto de vista técnico, permiten que las páginas web funcionen de forma más ágil y adaptada a las preferencias del usuario como, por ejemplo, almacenar su idioma o la moneda de su país.  Además, ayudan a los responsables de los sitios web a mejorar los servicios que ofrecen, gracias a la información estadística que recogen a través de ellas. Finalmente, sirven para hacer más eficiente la publicidad que le mostramos, gracias a la cual le podemos ofrecer servicios de forma gratuita.
                </p>
            </div>
            <div class="legal-block">
                <div class="title">
                    ¿Qué tipos de cookies existen?
                </div>
                <p>
                    En el Sitio Web se utilizan los siguientes tipos de cookies:
                </p>
                <ul>
                    <li class="mb-3">
                        <strong>Según su duración:</strong>
                        <ul class="mt-2">
                            <li><strong>Cookies de sesión:</strong> Son aquellas que duran el tiempo que el usuario está navegando por la Web y se borran al término.</li>
                            <li><strong>Cookies persistentes:</strong> Son aquellas que quedan almacenadas en el terminal del usuario por un tiempo más largo, facilitando así el control de las preferencias elegidas sin tener que repetir ciertos parámetros cada vez que se visite la Web.</li>
                        </ul>
                    </li>
                    <li class="mb-3">
                        <strong>Según su titular:</strong>
                        <ul class="mt-2">
                            <li><strong>Cookies propias:</strong> Son cookies creadas por el titular de la Web y que solo puede leer el propio sitio. Por ejemplo: cookies técnicas para la carga de imágenes, cookies de personalización de parámetros de la web, cookies de análisis de tráfico, etc.</li>
                            <li><strong>Cookies de terceros:</strong> Son cookies creadas por terceros y que se utilizan por el titular de una web para diferentes servicios (por ej. Análisis del sitio web o publicidad).</li>
                        </ul>
                    </li>
                    <li class="mb-3">
                        <strong>Según su finalidad:</strong>
                        <ul class="mt-2">
                            <li><strong>Cookies técnicas:</strong> Son aquellas imprescindibles y estrictamente necesarias para el correcto funcionamiento de una Web y la utilización de las diferentes opciones y servicios que ofrece. Por ejemplo, las que sirven para el mantenimiento de la sesión, la gestión del tiempo de respuesta, rendimiento o validación de opciones, utilizar elementos de seguridad, compartir contenido con redes sociales, etc.</li>
                            <li><strong>Cookies de personalización:</strong> Son las que permiten al usuario especificar o personalizar algunas características de las opciones generales de la Web. Por ejemplo, definir el idioma, configuración regional o tipo de navegador.</li>
                            <li><strong>Cookies analíticas:</strong> Son las utilizadas por una Web, para elaborar perfiles de navegación y poder conocer las preferencias de los usuarios del mismo con el fin de mejorar la oferta de productos y servicios. Por ejemplo, mediante una cookie analítica se controlarían las áreas geográficas de mayor interés de un usuario, cuál es el producto de más aceptación, etc.</li>
                            <li><strong>Cookies publicitarias:</strong> Son las que permiten la gestión de los espacios publicitarios en base a criterios concretos. Por ejemplo, la frecuencia de acceso, el contenido editado, etc. Las cookies de publicidad permiten a través de la gestión de la publicidad, almacenar información del comportamiento a través de la observación de hábitos, estudiando los accesos y formando un perfil de preferencias del usuario, para ofrecerle publicidad relacionada con los intereses de su perfil.</li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="legal-block">
                <div class="title">
                    Cookies exentas y no exentas:
                </div>
                <p>
                    Hay determinadas cookies sobre las que no es necesario informar legalmente ni solicitar un consentimiento expreso para su uso. Dichas cookies son las técnicas y las necesarias para el funcionamiento de la Web o la prestación de servicios expresamente solicitados por el usuario.
                </p>
                <p>
                    Todas las demás, se consideran no exentas, con lo que el prestador de servicios ha de informar y en su caso, solicitar el consentimiento para su uso.
                </p>
            </div>
            <div class="legal-block">
                <div class="title">
                    ¿Cómo pueden deshabilitarse las cookies?
                </div>
                <p>
                    La mayoría de los navegadores web permiten gestionar las preferencias de cookies, para tener un control más preciso sobre la privacidad.
                </p>
                <p>
                    Los siguientes links muestran las instrucciones para desactivar la configuración de las cookies en cada navegador:
                </p>
                <ol>
                    <li>
                        Internet Explorer (<a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies</a>)
                        <ul>
                            <li>
                                En el menú de herramientas, seleccione “Opciones de Internet”.
                            </li>
                            <li>
                                Haga clic en la pestaña de privacidad.
                            </li>
                            <li>
                                Podrá configurar la privacidad con un cursor con seis posiciones que le permite controlar la cantidad de cookies que se instalarán: Bloquear todas las cookies, Alta, Media Alto, Media (nivel por defecto), Baja, y Aceptar todas las cookies.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Mozilla Firefox (<a href="http://goo.gl/QXWYmv" target="_blank">http://goo.gl/QXWYmv</a>)
                        <ul>
                            <li>
                                En la parte superior de la ventana de Firefox hacer clic en el menú Herramientas.
                            </li>
                            <li>
                                Seleccionar Opciones.
                            </li>
                            <li>
                                Seleccionar el panel Privacidad.
                            </li>
                            <li>
                                En la opción Firefox podrá elegir Usar una configuración personalizada para el historial para configurar las opciones.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Google Chrome (<a href="http://goo.gl/fQnkSB" target="_blank">http://goo.gl/fQnkSB</a>)
                        <ul>
                            <li>
                                Hacer clic en el menú situado en la barra de herramientas.
                            </li>
                            <li>
                                Seleccionar Configuración.
                            </li>
                            <li>
                                Hacer clic en Mostar opciones avanzadas.
                            </li>
                            <li>
                                En la sección ‘Privacidad’ hacer clic en el botón Configuración de contenido.
                            </li>
                            <li>
                                En la sección de ‘Cookies’ se pueden configurar las opciones.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Safari (<a href="https://goo.gl/PCjEm3" target="_blank">https://goo.gl/PCjEm3</a> / <a href="https://goo.gl/dQywEo)" target="_blank">https://goo.gl/dQywEo)</a>
                        <ul>
                            <li>
                                En el menú de configuración, seleccione la opción de “preferencias”.
                            </li>
                            <li>
                                Abra la pestaña de privacidad.
                            </li>
                            <li>
                                Seleccione la opción que quiera de la sección de “bloquear cookies”.
                            </li>
                            <li>
                                Recuerde que ciertas funciones y la plena funcionalidad del Sitio pueden no estar disponibles después de deshabilitar las cookies.
                            </li>
                        </ul>
                    </li>
                </ol>
                <p>
                    La Web también usa cookies en dispositivos móviles. En este caso, al igual que sucede en los navegadores de ordenadores, los navegadores de los dispositivos móviles permiten configurar las opciones o ajustes de privacidad para desactivar o eliminar las cookies.
                </p>
                <p>
                    Si desea modificar las opciones de privacidad siga las instrucciones especificadas por el desarrollador de su navegador para dispositivo móvil.
                </p>
                <p>
                    Le ofrecemos a continuación algunos ejemplos de los links que le guiarán en la configuración de las opciones de privacidad en su dispositivo móvil:
                </p>
                <ul>
                    <li>IOS: (<a href="http://goo.gl/61xevS" target="_blank">http://goo.gl/61xevS</a>)</li>
                    <li>Windows Phone: (<a href="https://goo.gl/tKyb0y" target="_blank">https://goo.gl/tKyb0y</a>)</li>
                    <li>Chrome Mobile: (<a href="http://goo.gl/XJp7N" target="_blank">http://goo.gl/XJp7N</a>)</li>
                    <li>Opera Mobile: (<a href="http://goo.gl/Nzr8s7" target="_blank">http://goo.gl/Nzr8s7</a>)</li>
                </ul>
                <p>
                    Para más información sobre las cookies y su funcionamiento, puede consultar además los siguientes enlaces:
                    <ul>
                        <li><a href="https://www.aepd.es/sites/default/files/2020-07/guia-cookies.pdf" target="_blank">Guía sobre el uso de las cookies de la Agencia Española de Protección de Datos (jul.20)</a></li>
                        <li><a href="http://www.allaboutcookies.org/es/" target="_blank">http://www.allaboutcookies.org/es/</a></li>
                        <li><a href="http://www.youronlinechoices.com/es/" target="_blank">http://www.youronlinechoices.com/es/</a></li>
                    </ul>
                </p>
            </div>
            <div class="legal-block">
                <div class="title">
                    ¿Qué tipos de cookies utilizamos en esta Web?
                </div>
            </div>
            <div class="legal-block">
                <div class="title">
                    Aceptación de cookies:
                </div>
                <p>
                    Cuando accedas a la Web por primera vez visualizarás el configurador de cookies exigido tras la última modificación normativa.  En dicho configurador, podrás encontrar el detalle de todas y cada una las cookies que pueden instalarse con el uso de la Web: cookies técnicas, funcionales, analíticas, publicitarias…Pichando en cada una de las tipologías podrás encontrar a quién pertenece dicha cookie, cuál es su finalidad y cuál es su duración.
                </p>
                <p>
                    <u>Si no haces nada, únicamente se utilizarán las cookies estrictamente necesarias para el funcionamiento de la Web. </u>
                </p>
                <p>
                    Puedes seleccionar que el resto de cookies que existen en la Web se instalen también, pero deberás aceptarlo específicamente ajustando los controles del configurador de cookies según tus preferencias.
                </p>
                <p>
                    SOMOS BUEN PUERTO te agradece que active la aceptación de cookies, ya que dicha información es utilizada en exclusiva para mejorar el rendimiento de nuestra página, detectar nuevas necesidades y valorar las mejoras a introducir con la finalidad de prestar un mejor servicio.
                </p>
                <p>
                    Al desactivar las Cookies es posible que no funcionen ciertas opciones o que el usuario no pueda hacer uso de determinados servicios de nuestra Web.
                </p>
            </div>
            <div class="legal-block">
                <div class="title">
                    Información adicional sobre Google Analytics
                </div>
                <p>
                    Google Analytics, es un servicio analítico de web prestado por Google, Inc., una compañía de Delaware cuya oficina principal está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos. Esta compañía almacena las cookies en servidores ubicados en Estados Unidos y se compromete a no compartirla con terceros, excepto en los casos en los que sea necesario para el funcionamiento del sistema o cuando la ley obligue a tal efecto. En cualquier caso, y como consecuencia de la anulación del Privacy Shield, no podemos garantizar que los datos sean tratados con un nivel de protección acorde a la normativa europea por lo que si no estás de acuerdo con este tratamiento no aceptes la instalación de estas cookies.
                </p>
                <p>
                    Por el uso de Google Analytics se recopila información de forma anónima sobre cómo utilizan nuestra Web los usuarios. Estas cookies no recopilan datos de carácter personal de carácter identificativo, sino que recopilan número de visitas a la Web o secciones visitadas, tiempo de navegación, detalles sobre los navegadores usados... y realizan posteriormente informes estadísticos sobre el tráfico de la Web.
                </p>
                <p>
                    Además de toda la anterior información aportada, si deseas no ser rastreado por las cookies de Google Analytics, puedes instalarte en tu navegador el siguiente complemento desarrollado por Google a través de este enlace: <a href="http://goo.gl/up4ND" target="_blank">http://goo.gl/up4ND</a>.
                </p>
            </div>
            <div class="legal-block">
                <div class="title">
                    Cookies de redes sociales:
                </div>
                <p>
                    Además de lo anterior, la Web podrá utilizar servicios de cookies de redes sociales. Estas cookies permiten identificar a los usuarios para saber si están logados y si han interactuado con cualquiera de nuestros perfiles en redes sociales.
                </p>
                <p>
                    Los datos obtenidos en estos casos, serán tratados conforme a las políticas de privacidad y cookies correspondientes a cada red social: <a href="https://help.twitter.com/es/rules-and-policies/twitter-cookies" target="_blank">Twitter</a>, <a href="https://policies.google.com/technologies/types?hl=es" target="_blank">YouTube</a>, <a href="https://www.facebook.com/policies/cookies/" target="_blank">Facebook</a>, <a href="https://www.linkedin.com/legal/cookie-policy?_l=es_ES" target="_blank">LinkedIn</a>...
                </p>
            </div>
            <div class="legal-block">
                <div class="title">
                    Actualizaciones en la Política de Cookies
                </div>
                <p>SOMOS BUEN PUERTO puede modificar esta Política de Cookies en función de exigencias legislativas, reglamentarias, o con la finalidad de adaptar dicha política a las instrucciones dictadas por la Agencia Española de Protección de Datos, por lo que se aconseja a los usuarios que la visiten periódicamente.</p>
            </div>
        </b-container>
    </div>
</template>

<script>
//import NavBar from '@/components/NavBar'

export default {
    name: 'PoliticaDeCookies',
    components: {
    },
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>